<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="manifesto-page" >
		<div v-for="(item, key) in content" :key="key">
			<p v-if="item.type === 'paragraph'" ref="text">
				{{item.children[0].text}}
			</p>
		</div>
		<!-- <div class="moto-container">
			<img :src="moto" alt="" ref="moto">
		</div> -->
		<div class="lottie-container" ref="lottieContainer">
			<div class="lottie" ref="bodymovin">
			</div>
		</div>
		
	</div>
</template>
<script>
import axios from 'axios';
import store from '@/assets/js/store'
import GlobalEvents from '@/assets/js/utils/GlobalEvents'
import { E } from '@/assets/js/utils'
import moto from '@/assets/image/moto.png'
import SplitType from 'split-type'
import lottie from 'lottie-web'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsap from 'gsap'
gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'Manifesto-comp',
  components: {},
  props: {
    data: Object
  },
  watch: {
	content(){
		if(store.isLoaded){
			this.addText()
		}
		setTimeout(() => {
			// this.addImage()
			E.emit('domCanvasAdd',{el:this.anim.renderer.svgElement})

		}, 500)
	}
  },
  data () {
    return {
		apiUrl: process.env.VUE_APP_API,
		content: [],
		moto: moto,
		lineCount: 0
	}
  },
  async created () {
	setTimeout(() => {
		if(store.isLoaded) {
			store.Lenis.dimensions.resize()
		}
	}, 500)
	try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/manifesto`)
      this.content = response.data.data.attributes.content
    } catch (error) {
      this.error = error;
    }
  },
  mounted () {
	this.anim =  lottie.loadAnimation({
		container: this.$refs.bodymovin, // Required
		path:'../anim/forusbyus.json', // Required
		renderer: 'svg', // Required
		loop: false, // Optional
		autoplay: false, // Optional
		name: "forusbyus", // Name for future reference. Optional.
	})
	this.animListener = this.anim.addEventListener('drawnFrame', () => {
		
		E.emit('domSvgUpdate', this.anim.renderer.svgElement)
		
	})
	E.on(GlobalEvents.RESIZE, this.onResize)
	if(!store.isLoaded){
		E.on('LoaderOut',() => {
			this.setScrollTrigger()
			this.addText()
			// E.emit('domCanvasAdd',{el:this.anim.renderer.svgElement})
			setTimeout(() => {
				E.emit('isPlainBackground', 1)
			}, 1)
		})
	} else {
		setTimeout(() => {
			this.setScrollTrigger()
		}, 500)
		setTimeout(() => {
			E.emit('isPlainBackground', 1)
			
		}, 1)
	}
  },
  beforeUnmount(){
	// E.emit('domCanvasLeave', this.$refs.moto)
	
	this.lineCount = 0
	this.anim.stop()
	E.emit('domCanvasLeave',  this.anim.renderer.svgElement)
	this.anim.addEventListener('drawnFrame', this.animListener )
	this.scrollLottie.kill()
	this.$refs.text.forEach(el => {
		el.split.lines.forEach((el) => {
			E.emit('domCanvasLeave', el)
		})
	})
	lottie.destroy('forusbyus')
  },
  methods: {
	addText() {
		if(!store.isLoaded){
			E.on('PageLoaded',() => {
			setTimeout(() => {
				this.$refs.text.forEach((el) => {
					el.split = new SplitType(el,{ types: 'lines' })
					el.split.lines.forEach((el) => {
						E.emit('domCanvasAdd', { el: el, opacity: 0, y: 100, x: 0, id: this.lineCount})
						this.lineCount++
					})
					
				})
			}, 10)
		})
		} else {
			this.$nextTick(() => {
				this.$refs.text.forEach((el) => {
					el.split = new SplitType(el,{ types: 'lines' })
					el.split.lines.forEach((el) => {
						E.emit('domCanvasAdd', { el: el, opacity: 0, y: 100, x: 0, id: this.lineCount})
						this.lineCount++
					})
					
				})
			})
		}
	},
	addImage(){
		E.emit('domCanvasAdd', { el: this.$refs.moto, opacity: 0, y: 0, x: 0})
	},
	setScrollTrigger(){
		this.scrollLottie =  ScrollTrigger.create({
			trigger:this.$refs.bodymovin,
			start: "top bottom",
			end: "bottom bottom",
			scrub: 1,
			onLeaveBack: () => {
				this.anim.stop()
				// this.anim.goToAndStop(0)
			},
			onLeave: () => {
				// this.anim.goToAndPlay(0)
				this.anim.play()
			}
		})
		// this.scrollLottieExit =  ScrollTrigger.create({
		// 	trigger:this.$refs.bodymovin,
		// 	start: "top bottom",
		// 	end: "top center",
		// 	scrub: 1,
		// 	onLeaveBack: () => {
		// 		this.anim.stop()
		// 	}
		// })
	},
	onResize(){
		this.$refs.text.forEach((el, i) => {
			el.split.lines.forEach((el) => {
				E.emit('domCanvasLeave', el)
			})
			el.split.split()
			el.split.lines.forEach((el, id) => {
				E.emit('domCanvasAdd', { el: el, opacity: 1, y: 0, x: 0, id: id + i})
			})
		})
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>