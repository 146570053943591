import {Gui} from './utils/Gui'
import store from './store'
// import { E } from './'

export default class CustomGUI extends Gui {
	constructor() {
		super()

		this.sphereConfig()
		// this.addAISphereGLobal()
		// this.addChimere()
	}

	sphereConfig(){
		if (!store.MainScene.components.logo.token) return
		const folder = this.addFolder({ title: 'Logo', expanded: false })

		const logoMovement = folder.addBinding(
			store.MainScene.components.logo.tokenMaterial.uniforms
				.uLogoMovement,
			'value',
			{
				min: 0,
				max: 0.1,
				step: 0.001,
				label: 'Logo Rotation',
			}
		)
		logoMovement.on('change', (e) => {
			store.MainScene.components.logo.backfaceTokenMaterial.uniforms.uLogoMovement.value = e.value
		})

		folder.addBinding(
			store.MainScene.components.logo.tokenMaterial.uniforms
				.uIOR,
			'value',
			{
				min: 0,
				max: 2,
				step: 0.001,
				label: 'IOR',
			}
		)
		folder.addBinding(
			store.MainScene.components.logo.tokenMaterial.uniforms
				.uFresnelVal,
			'value',
			{
				min: 0,
				max: 2,
				step: 0.001,
				label: 'Fresnel/Opacity',
			}
		)
		folder.addBinding(
			store.MainScene.components.logo.tokenMaterial.uniforms
				.uRefractPower,
			'value',
			{
				min: 0,
				max: 1,
				step: 0.001,
				label: 'Refract Power',
			}
		)

		folder.addBinding(
			store.MainScene.components.logo.tokenMaterial.uniforms
				.uDiffuseMatcapBlend,
			'value',
			{
				min: 0,
				max: 1,
				step: 0.001,
				label: 'Diffuse matcap',
			}
		)

		const blendSelect = folder.addBlade({
			view: 'list',
			label: 'scene',
			options: [
				{text: '0', value: '0'},
				{text: '1', value: '1'},
				{ text: '2', value: '2' },
				{ text: '3', value: '3' },
				{ text: '4', value: '4' }],
			value: store.MainScene.components.logo.tokenMaterial.uniforms
			.uDiffuseMatcapBlend.value
		})

		blendSelect.on('change', (e) => {
			store.MainScene.components.logo.tokenMaterial.uniforms
				.uDiffuseMatcapBlend.value = e.value

		})

		folder.addBinding(
			store.MainScene.components.logo,
			'tokenChange',
			{
				label: 'Change logo',
			}
		)
		

		// folder.addInput(store.WebGL.skyUnderwaterTransition.transitionPass.material.uniforms.uWarpScale, 'value', {
		// 	min: 0,
		// 	max: 1,
		// 	step: 0.001,
		// 	label: 'uWarpScale'
		// })

		// folder.addInput(store.WebGL.skyUnderwaterTransition.transitionPass.material.uniforms.uWarpStrength, 'value', {
		// 	min: 0,
		// 	max: 2,
		// 	step: 0.001,
		// 	label: 'uWarpStrength'
		// })
	}

}
