<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="directors-page">
		<div class="textContent">
			<h1>{{ content.name }}</h1>
			<a :href="content.instagramLink">instagram</a>
		</div>
		<ul class="projects" >
			<ProjectTease :data="project" v-for="(project, key) in projects" :key="key"/>
		</ul>
	</div>
</template>
<script>
import axios from 'axios';
import { useRoute } from 'vue-router'
import ProjectTease from '@/components/ProjectTease'
import {E} from '@/assets/js/utils'
import store from '@/assets/js/store'
export default {
  name: 'Directors-page',
  components: {
	ProjectTease
  },
  props: {
    data: Object
  },
  data () {
    return {
		content: {},
		projects: []
	}
  },
  mounted () {
	E.on('PageLoaded',() => {
		store.cursor.start()
		store.cursor.appear()
	})
	setTimeout(() => {
		if(store.isLoaded) {
			store.cursor.start()
			store.cursor.appear()
			store.Lenis.dimensions.resize()
		}
	}, 500)
  },

  async created () {
	this.route = useRoute()

    try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/directors?filters[slug][$eq]=${this.route.params.id}&populate=deep`)
      this.content = response.data.data[0].attributes
    } catch (error) {
      this.error = error;
    }

	try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/projects?filters[director][slug][$eq]=${this.route.params.id}&sort=rank:asc&populate=deep`)
      this.projects = response.data.data
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
	
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>