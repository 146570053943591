<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="project-component" v-if="activeProject.mediaList && open">
		<div class="go-left" @click="nextProject(-1)">
		</div>
		<div class="go-right" @click="nextProject(1)">
		</div>
		<div class="menu">
			<div class="left">
				<div class="name">
					{{ activeProject.name }}
				</div>
				<div class="credits" @click="toggleCredits">
					view credits
				</div>
			</div>
			<div class="right">
				<button @click="close">close</button>
			</div>
		</div>
		<div class="credit-pannel" :class="{show: showCredit}" data-lenis-prevent>
			<div class="credit-pannel-item" v-for="(item, key) in activeProject.Credits" :key="key">
				<div class="credit-pannel-item-role">{{ item.role }}</div> 
				<div class="credit-pannel-item-names" v-html="item.name[0].children[0].text.replaceAll('\n', '</br>')">
				</div>
			</div>
		</div>
		<!-- {{ activeProject.mediaList[activeMedia].__component }} -->
		<div class="video-container" :class="{blurred: showCredit}" v-if="activeProject.mediaList[activeMedia].__component === 'projects.video'">
			<videoplayer
				class="videoplayer"
				:src="activeProject.mediaList[activeMedia].link"
				:muted="false"
				:autoplay="true"
				:controls="false"
				:loop="false"
				@play="onPlayerPlay"
				@pause="onPlayerPause"
				@ended="onPlayerEnded"
				@loadeddata="onPlayerLoadeddata"
				@waiting="onPlayerWaiting"
				@playing="onPlayerPlaying"
				@timeupdate="onPlayerTimeupdate"
				@canplay="onPlayerCanplay"
				@canplaythrough="onPlayerCanplaythrough"
				@statechanged="playerStateChanged"
				>
				<template
					v-slot:controls="{
					togglePlay,
					playing,
					percentagePlayed,
					seekToPercentage,
					// duration,
					// convertTimeToDuration,
					videoMuted,
					toggleMute,
					fullScreen
					}"
				>
					<div class="videoplayer-controls">
					<button @click="togglePlay()" class="videoplayer-controls-toggleplay">
						{{ playing ? "pause" : "play" }}
					</button>
					<!-- <div class="videoplayer-controls-time">
						{{ convertTimeToDuration(time) }} /
						{{ convertTimeToDuration(duration) }}
					</div> -->
					<videoplayer-track
						:percentage="percentagePlayed"
						@seek="seekToPercentage"
						class="videoplayer-controls-track"
					/>
					<button @click="toggleMute()" class="videoplayer-controls-togglemute">
						{{ videoMuted ? "unmute" : "mute" }}
					</button>
					<button @click="fullScreen()" class="videoplayer-controls-togglemute">
						fullscreen
					</button>
					</div>
				</template>
			</videoplayer>
		</div>
		<div class="image-container" v-else>
			<img :src="apiUrl + activeProject.mediaList[activeMedia].image.data.attributes.url" alt="">
		</div>
	</div>
</template>
<script>

import videoplayer from "../videoplayer";
import videoplayerTrack from "../videoplayer-track";
export default {
  name: 'Project-comp',
  components: {
	videoplayer,
	videoplayerTrack
  },
  props: {
    activeProject: Object,
	open: Boolean,
	close: Function,
  },
  data () {
    return {
		activeMedia: 0,
		time: 0,
		showCredit: false,
		apiUrl: process.env.VUE_APP_API
	}
  },
  created () {
  },
  mounted () {
	
  },
  methods: {
	nextProject(val){
		let tempVal = this.activeMedia
		tempVal += val
		tempVal = tempVal % (this.activeProject.mediaList.length)
		if(tempVal < 0) tempVal = (this.activeProject.mediaList.length - 1)
		console.log(tempVal)
		this.activeMedia = tempVal
		
	},
	toggleCredits(){
		this.showCredit = !this.showCredit
	},
	onPlayerPlay({player }) {
      player.setPlaying(true);
    },
    onPlayerPause({player }) {
      player.setPlaying(false);
    },
    onPlayerEnded({player }) {
      player.setPlaying(false);
    },
    onPlayerLoadeddata() {
    },
    onPlayerWaiting() {
    },
    onPlayerPlaying() {
    },
    onPlayerTimeupdate({event}) {
      this.time = event.target.currentTime;
    },
    onPlayerCanplay() {
    },
    onPlayerCanplaythrough() {
    },
    playerStateChanged() {
    }
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>