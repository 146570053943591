<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="talent-page">
		<DirectorsList/>
		<video id="videoContainer" ref="video" src="https://res.cloudinary.com/dx0u7r6hd/video/upload/v1716465538/tempVid_b935ka.mp4" crossorigin="anonymous" muted playsinline loop></video>
	</div>
</template>

<script>
import DirectorsList from '@/components/DirectorsList'
import { E } from '@/assets/js/utils'
import store from '@/assets/js/store'
export default {
  name: 'Talent-page',
  components: {
	DirectorsList
  },
  props: {
    data: Object
  },
  data () {
    return {
		videoTitle: 'tempVid'
	}
  },
  created () {
  },
  mounted () {
	E.emit('PageSwitch')
	if(store.isLoaded) store.Lenis.dimensions.resize()
  },
  methods: {
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>