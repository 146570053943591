import { Group, WebGLRenderTarget, Raycaster, PlaneGeometry, Mesh, Vector2, MeshBasicMaterial } from 'three'
import {  TokenMaterial, BackFaceToken } from '../materials'
import store from '../store'
import { E } from '../utils'
import GlobalEvents from '../utils/GlobalEvents'
import gsap from 'gsap'
export default class Logo extends Group {
	constructor() {
		super()
		this.vel = new Vector2()
		this.load()
		this.renderOrder = 20
		this.introProgressTemp = 0
		this.introProgress = 0
		this.tokenChange = true
	}
	build() {
		this.createSingle(0)
		this.createSingle(1)

		this.mouseInteraction()
		
		store.RAFCollection.add(this.onRaf, 0)
		// this.rotation.set(-.5, 0, 0)
		// this.position.set(0, 0, -5)
		E.on(GlobalEvents.RESIZE, this.onResize)
		// E.on('LoaderOut', () => {
		// 	this.appear()
		// 	E.on(GlobalEvents.RESIZE, this.onResize)
		// 	gsap.delayedCall(.5, () => {
		// 		this.appearTimeline.play()
		// 	})
		// })
		E.on('introProgress', (e) => {
			this.introProgress = e.value
		})
		E.on('introEnter', this.onEnter)
		E.on('introLeave', this.onLeave)
	}

	onLeave = () => {
		// if(store.isSafari){
			gsap.delayedCall(0.2, () => {
				store.RAFCollection.remove(this.onRaf, 0)
				gsap.to(this.tokenMaterial.uniforms.uPos0.value, {x: 0, y: -40, z: 0})
			})
			// store.RAFCollection.remove(this.onRaf, 0)
			// gsap.to(this.tokenMaterial.uniforms.uPos0.value, {x: 0, y: -20, z: 0})
			this.backfaceTokenMaterial.uniforms.uPos0.value = this.tokenMaterial.uniforms.uPos0.value
		// }
	}

	onEnter = () => {
		// if(store.isSafari){
			// store.RAFCollection.add(this.onRaf, 0)
		// }
	}

	appear() {
		// this.appearTimeline = gsap.timeline({ paused: true })
		// this.appearTimeline.addLabel('tokenAppear')
		// this.appearTimeline.fromTo(this.tokenMaterial.uniforms.uAppear, { value: 0}, { value: 2, duration: 4}, 'tokenAppear')
		// this.appearTimeline.to(this.token.rotation, { z: Math.PI * 2, duration: 1}, 'tokenAppear+=0.5')
		// this.appearTimeline.to(this.token.position, { y: 0, z: 4, duration: 1.2, ease: "back.out(5)"}, 'tokenAppear+=0.5')

		// this.appearTimeline.addLabel('gridAppear', '-=2')
		// this.appearTimeline.fromTo(this.gridMaterial.uniforms.uAppear, { value: 0},
		// 	{ 
		// 		value: 30,
		// 		duration: 2,
		// 		onUpdate: () => {
		// 			this.backfaceMaterial.uniforms.uAppear.value = this.gridMaterial.uniforms.uAppear.value
		// 		}
		// }, 'gridAppear-=.5')
		
		// this.tokenLeaveTimeline = gsap.timeline({ paused: true })
		// this.tokenLeaveTimeline.to(this.token.position, { y: 5.5, duration: 1.2}, 0)
		// this.tokenLeaveTimeline.to(this.tokenMaterial.uniforms.uIOR, { value: 1, duration: 1.2}, 0)
		// this.tokenLeaveTimeline.to(this.token.scale, { x: 1, y: 1, z: 1, duration: 1.2}, 0)
	}

	introLeave() {
		// if(this.tokenLeaveTimeline) {
		// 	this.tokenLeaveTimeline.progress(e)
		// }
		// this.gridMaterial.uniforms.uLeave.value = e * 6
		// this.backfaceMaterial.uniforms.uLeave.value = e * 6
		// // this.backfaceTokenMaterial.uniforms.uLeave.value = e
		// // this.token
		// // this.gridMaterial.uniforms.uLeave.value = e
	}


	createSingle(id) {
		const geometry = id === 0 ? this.token : this.token1
		this.backfaceTokenFbo = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		this.envTokenFbo = new WebGLRenderTarget(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		this.tokenMaterial = new TokenMaterial({
			backfaceMap: this.backfaceTokenFbo.texture,
			envMap: this.envTokenFbo.texture,
			// tNormal: this.normalMap,
			tMatCap: this.matCapToken,
			resolution: new Vector2(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		})
		this.backfaceTokenMaterial = new BackFaceToken({
			// tNormal: this.normalMap,
		})
		if(id === 0) {
			this.token = new Mesh(geometry, this.backfaceTokenMaterial)
			this.token.rotation.set(Math.PI * 0.5, 0, 0)
			this.token.position.set(0, 0, 1)
			this.token.scale.set(.8, .8, .8)
			this.token.scale.set(30, 30, 30)
			this.add(this.token)
		} else {
			this.token1 = new Mesh(geometry, this.backfaceTokenMaterial)
			this.token1.rotation.set(Math.PI * 0.5, 0, 0)
			this.token1.position.set(0, 0, 1)
			this.token1.scale.set(.8, .8, .8)
			this.token1.scale.set(30, 30, 30)
			this.add(this.token1)
		}
		// this.token = new Mesh(geometry, this.backfaceTokenMaterial)
		// this.token.rotation.set(Math.PI * 0.5, 0, 0)
		// this.token.position.set(0, 0, 1)
		// this.token.scale.set(.8, .8, .8)
		// this.token.scale.set(30, 30, 30)
		// this.add(this.token)
	}
	mouseInteraction() {
		const hitplane = new Mesh(
			new PlaneGeometry(),
			new MeshBasicMaterial()
		) 
		hitplane.scale.setScalar(50)
		hitplane.position.set(0, 0, 0)
		// hitplane.rotation.x = -Math.PI/2
		hitplane.updateMatrix()
		hitplane.updateMatrixWorld()
		let raycaster = new Raycaster()
		// this.add(hitplane)

		this.mouse = new Vector2()
		let v2 = new Vector2()
		window.addEventListener('mousemove', (ev)=>{
			let x = ev.clientX / (window.innerWidth)  - 0.5
			let y = ev.clientY / (window.innerHeight)  - 0.5

			v2.x = x *2;
			v2.y = -y *2;
			raycaster.setFromCamera(v2, store.MainScene.camera)

			let intersects = raycaster.intersectObject(hitplane)

			if(intersects.length > 0){
				let first = intersects[0]
				this.mouse.x = first.point.x
				this.mouse.y = first.point.y
			}
		})
	}

	stop() {
		this.introProgressTemp = this.introProgress = 0
		store.RAFCollection.remove(this.movementEase)
		store.RAFCollection.remove(this.onRaf, 0)
		this.appearTimeline.seek(0)
		this.appearTimeline.pause()

		this.tokenLeaveTimeline.seek(0)
		this.tokenLeaveTimeline.pause()
		this.token.position.set(0, 0, -1)
		this.token.scale.set(2.5, 2.5, 2.5)
		this.gridMaterial.uniforms.uLeave.value = 0
		this.backfaceMaterial.uniforms.uLeave.value = 0

	}
	start() {
		// store.RAFCollection.add(this.movementEase)
		if(this.appearTimeline){
			this.appearTimeline.seek(0)
			this.appearTimeline.pause()
		}
		
		// store.RAFCollection.add(this.onRaf, 0)
		if(store.loaderOut){
			gsap.delayedCall(.5, () => {
				this.appearTimeline.play()
			})
		}
		
	}

	movementEase = () => {
		this.introProgressTemp += (this.introProgress - this.introProgressTemp ) * 0.3
		this.introLeave(this.introProgressTemp)
	}

	onRaf = () => {
		// Lerp uPos0 to mouse
		let v3 = new Vector2()
		v3.copy(this.mouse)

		v3.sub(this.tokenMaterial.uniforms.uPos0.value)
		v3.multiplyScalar(0.1)
		this.tokenMaterial.uniforms.uPos0.value.add(v3)
		// Get uPos1 Lerp speed 
		v3.copy(this.tokenMaterial.uniforms.uPos0.value)
		v3.sub(this.tokenMaterial.uniforms.uPos1.value)
		v3.multiplyScalar(0.1)
		// Lerp the speed
		v3.sub(this.vel)
		v3.multiplyScalar(0.9)
		this.vel.add(v3)
		// Add the lerped velocity
		if(!store.isMobile) {
			this.tokenMaterial.uniforms.uVel.value = this.vel.length() *2
			this.tokenMaterial.uniforms.uPos1.value.add(this.vel)
		}
		
		// this.tokenMaterial.uniforms.uPos0.value = this.gridMaterial.uniforms.uPos0.value
		this.backfaceTokenMaterial.uniforms.uPos0.value = this.tokenMaterial.uniforms.uPos0.value


		store.WebGL.renderer.setRenderTarget(null)

		if(this.tokenChange){
			this.token1.visible = true
			this.token.visible = false
	
			this.token1.material = this.backfaceTokenMaterial
			store.WebGL.renderer.setRenderTarget(this.backfaceTokenFbo)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
			this.token1.visible = false
			store.WebGL.renderer.setRenderTarget(this.envTokenFbo)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)

			store.WebGL.renderer.setRenderTarget(null)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
			this.token1.visible = true
			this.token1.material = this.tokenMaterial

		} else {
			this.token.visible = true
			this.token1.visible = false
	
			this.token.material = this.backfaceTokenMaterial
			store.WebGL.renderer.setRenderTarget(this.backfaceTokenFbo)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
			this.token.visible = false
			store.WebGL.renderer.setRenderTarget(this.envTokenFbo)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)

			store.WebGL.renderer.setRenderTarget(null)
			store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
			this.token.visible = true
			this.token.material = this.tokenMaterial
		}

		// this.token.visible = true
	
		// this.token.material = this.backfaceTokenMaterial
		// store.WebGL.renderer.setRenderTarget(this.backfaceTokenFbo)
		// store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
		// this.token.visible = false
		// store.WebGL.renderer.setRenderTarget(this.envTokenFbo)
		// store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)

		// store.WebGL.renderer.setRenderTarget(null)
		// store.WebGL.renderer.render(store.MainScene, store.MainScene.camera)
		// this.token.visible = true
		// this.token.material = this.tokenMaterial

	}
	load() {
		store.AssetLoader.loadGltf(`/models/logo3.glb`).then(gltf => {
			// this.pieces = gltf.children
			this.token1 = gltf.scene.children[0].geometry
		})
		store.AssetLoader.loadGltf(`/models/logo1.glb`).then(gltf => {
			// this.pieces = gltf.children
			this.token = gltf.scene.children[0].geometry
		})
		// store.AssetLoader.loadFbx(`/model/token.fbx`).then(gltf => {
		// 	// this.pieces = gltf.children
		// 	this.token = gltf.children[0].geometry
		// })
		store.AssetLoader.loadTexture(`/textures/tokenNormal1.png`, { flipY: false}).then(texture => {
			this.normalMap = texture
		})
		store.AssetLoader.loadTexture(`/textures/iridescent32.png`).then(texture => {
			this.matCap = texture
		})
		store.AssetLoader.loadTexture(`/textures/iridescent32.png`).then(texture => {
			this.matCapToken = texture
		})
	}

	onResize = () => {
		this.backfaceTokenFbo.setSize(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		this.envTokenFbo.setSize(store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio())
		
		this.tokenMaterial.uniforms.resolution.value = [store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio()]
		// this.backfaceMaterial.uniforms.resolution.value = [store.window.w * store.WebGL.renderer.getPixelRatio(), store.window.h * store.WebGL.renderer.getPixelRatio()]
	}
}