<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="contact-page" v-if="content.mail">
		<a class="mail" :href="`mailto:${content.mail.replaceAll('&amp;shy;', '')}`" v-html="setContent(content.mail)">
		</a>
		<a class="intagram domText" :href="content.Link.url" target="_blan" rel="noopener">{{ content.Link.text }}</a>
		<p class="domText">{{ content.address }}</p>

		<div class="credits"><a href="www.charlie-montagut.com">site by charlie</a></div>
	</div>
</template>
<script>
import axios from 'axios';
import store from '@/assets/js/store'
import { E, qsa } from '@/assets/js/utils'
import moto from '@/assets/image/moto.png'
export default {
  name: 'contact-comp',
  components: {},
  props: {
    data: Object
  },
  watch: {
	content(){
		// this.addText()
		// setTimeout(() => {
		// 	this.addImage()
		// }, 500)
	}
  },
  data () {
    return {
		apiUrl: process.env.VUE_APP_API,
		content: [],
		moto: moto
	}
  },
  async created () {
	setTimeout(() => {
		if(store.isLoaded) {
			store.Lenis.dimensions.resize()
		}
	}, 500)
	try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/contact?populate=*`)
      this.content = response.data.data.attributes
      console.log('TEST', this.content)
    } catch (error) {
      this.error = error;
    }
  },
  mounted () {
	if(!store.isLoaded){
		E.on('LoaderOut',() => {
			setTimeout(() => {
				E.emit('isPlainBackground', 1)
			}, 1)
		})
	} else {
		setTimeout(() => {
			E.emit('isPlainBackground', 1)
		}, 1)
	}
  },
  beforeUnmount(){
	this.items.forEach(el => {
		E.emit('domCanvasLeave', el)
	})
  },
  methods: {
	setContent(el){
		const spans = el.split('&shy;')
		let out = ''
		spans.forEach(el => {
			
			out += '<span class="domText">' + el + '</span>'
		})
		this.$nextTick(() => {
			this.addText(qsa('.domText'))
		})
		return out
	},
	addText(items) {
		this.items = items
		if(!store.isLoaded){
			E.on('LoaderOut',() => {
			setTimeout(() => {
				items.forEach((el, i) => {
					E.emit('domCanvasAdd', { el: el, opacity: 0, y: 100, x: 0, id: i})
				})
			}, 10)
		})
		} else {
			this.$nextTick(() => {
				items.forEach((el, i) => {
					E.emit('domCanvasAdd', { el: el, opacity: 0, y: 100, x: 0, id: i})
				})
			})
		}
	},
	addImage(){
		E.emit('domCanvasAdd', { el: this.$refs.moto, opacity: 0, y: 0, x: 0})
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>