<template>
	<canvas id="gl"></canvas>
	<nav>
		<svg ref="logo" class="logo" width="81" height="14" viewBox="0 0 81 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_296_412)">
				<path d="M5.65744 0.230957L0.115234 13.7443H3.40217L4.44789 11.0077H9.91427L10.959 13.7443H14.246L8.70472 0.230957H5.65744ZM5.45148 8.36646L7.18155 3.55408L8.91161 8.36646H5.45148Z" fill="white"/>
				<path d="M25.2069 5.78018C24.4214 5.59047 21.4518 5.2862 21.142 5.23304C20.3022 5.08916 19.851 4.80597 19.851 3.98206C19.851 3.15816 20.9444 2.56887 22.5088 2.56887C23.9252 2.56887 24.6967 2.93179 25.0374 3.91791C25.0402 3.92707 25.043 3.93624 25.0458 3.94632H28.2064C27.8394 1.76879 26.0522 0.000915527 22.4507 0.000915527C18.6049 0.000915527 16.7166 2.11246 16.7166 4.22584C16.7166 7.00092 18.677 7.82207 20.4361 8.14742C21.0072 8.25281 23.5161 8.52684 23.8737 8.58458C24.9223 8.75137 25.3033 9.04373 25.3033 9.91896C25.3033 10.8666 24.0572 11.3771 22.6361 11.3771C20.2788 11.3771 19.5832 10.4395 19.5832 8.97774H16.3843C16.637 11.586 17.9393 14.0009 22.6361 14.0009C26.422 14.0009 28.4039 12.1341 28.4039 9.53771C28.4039 7.80374 27.5183 6.26957 25.2069 5.78109V5.78018Z" fill="white"/>
				<path d="M45.4545 10.1967L42.2874 0.755178H39.3188L36.1526 10.1967L34.5218 0.230957H31.5288L33.6568 13.7443H37.6889C37.6889 13.7443 39.9179 6.98534 40.8036 4.5402C41.6892 6.98443 43.9183 13.7443 43.9183 13.7443H47.9504L50.0774 0.230957H47.0844L45.4545 10.1967Z" fill="white"/>
				<path d="M57.008 0.230957L51.4658 13.7452H54.7528L55.7975 11.0086H61.2639L62.3087 13.7452H65.5956L60.0553 0.230957H57.009H57.008ZM56.8021 8.36646L58.5321 3.55408L60.2622 8.36646H56.8021Z" fill="white"/>
				
				<path d="M77.9794 0.230957V9.69443C77.9148 9.5817 77.8502 9.46989 77.7847 9.36083L71.9794 0.230957H68.1672V13.7443H71.09V4.1672C71.1583 4.28725 71.2286 4.40914 71.3016 4.54112L77.1059 13.7443H80.8844V0.230957H77.9794Z" fill="white"/>
			</g>
			<defs>
			<clipPath id="clip0_296_412">
			<rect width="80.7692" height="14" fill="white" transform="translate(0.115234)"/>
			</clipPath>
			</defs>
		</svg>
		<img :src="moto" alt="">
	</nav>
	<div class="comming-soon" ref="scrollContainer" v-if="content.mail">
		<Loader/>
		<ProjectModalTemp ref="modal" :open="projectOpen" :close="projectClose" :activeProject="content.videoLink" :name="content.videoCredits" :mail="content.mail"/>
		
		<a class="mail-link" :href="content.mail.url">{{ content.mail.text }}</a>
		<p class="video-credits" v-html="isMobile ? content.videoCredits.replace('-', '</br>') : content.videoCredits"></p>
	</div>
	<div class="videoCollection">
			<video id="videoContainer" :src="content.videoLink" crossorigin="anonymous" muted playsinline loop autoplay></video>
		</div>
</template>

<script>
import Loader from './components/Loader'
import ProjectModalTemp from './components/ProjectModalTemp'
import E from './assets/js/utils/E'
import GlobalEvents from './assets/js/utils/GlobalEvents'
import store from './assets/js/store'
import axios from 'axios';
import moto from '../src/assets/image/moto.png'
import Lenis from 'lenis'
// import showreel from '../video/showreel.pm4'

export default {
  name: 'App',
  components: {
    Loader,
	ProjectModalTemp
  },
  data: () =>{
	return {
		location: {},
		directors: [],
		navLocation : '',
		appear: false,
		moto: moto,
		showMenu: true,
		isMobile: store.isMobile,
		menuOpen: false,
		contactInfo: {},
		content: {},
		projectOpen: true,
	}
  },
  async created () {
	try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/comingsoon?populate=*`)
      this.content = response.data.data.attributes
    } catch (error) {
      this.error = error;
    }
  },
  mounted() {
	E.on(GlobalEvents.RESIZE, this.onResize)
	E.on('LoaderOut',() => {
		
	})

	const vh = store.window.h * 0.01
	document.documentElement.style.setProperty('--vh', `${vh}px`)

	store.Lenis = new Lenis({
		wrapper: this.$refs.scrollContainer,
		// content: document.querySelector('body'),
		gestureOrientation: 'vertical',
		syncTouch: true,
		autoResize: true,
		wrapperResizeObserver: this.$refs.scrollContainer
	})
  },
  methods: {
	onResize() {
		const vh = store.window.h * 0.01
		document.documentElement.style.setProperty('--vh', `${vh}px`)
	},
	hideMenu(val){
		this.showMenu = !val
	},
	toggleMenu(){
		this.menuOpen = !this.menuOpen
	}
  }
}
</script>

<style lang="less">
@import './assets/less/_imports.less';

#gl {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
}

nav{
	display: flex !important;
	justify-content: space-between;
	width: calc(100vw - 40px);
	align-items: center;
	padding: 10px 20px !important;

	svg{
		height: 50px;
	}
	img{
		height: 50px;
	}
}

.mail-link, .video-credits{
	color: @white;
	text-decoration: none;
	font-family: @mainFont;
	position: absolute;
	text-decoration: none;
	// transform: translateX(-50%);
	display: block;
	font-size: 12px;
	text-transform: uppercase;
	line-height: 14.4px
}

.mail-link{
	// right: 20px;
	// bottom: 20px;
	// @media @mobile{
	// 	right: auto;
	// 	left: 50%;
	// 	transform: translateX(-50%);
	// }
}
.video-credits{
	left: 20px;
	bottom: 20px;
	@media @mobile{
		right: auto;
		left: 50%;
		transform: translateX(-50%);
		bottom: 140px;
		width: 100%;
	}
}

.textCanvas {
	position: absolute;
	top: 0;
	left: 0;
	// background-color: blue;
	
	width: 100%;
	height: 100%;
	z-index: -1;
	opacity: 1;
}

#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	font-weight: 900;
	//   margin-top: 60px;
}

.lenis.lenis-smooth [data-lenis-prevent] { overscroll-behavior: contain; }

.scroll-container {
	scroll-behavior: auto !important;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100) !important;
	overflow: hidden;
	position: relative;
	scrollbar-width: none;
	-ms-overflow-style: none;
	overscroll-behavior: none;
	z-index: 10;

	&.isCursor{
		cursor: none;
	}
}

.videoCollection {
	position: fixed;
	top: 0;

	video {
		position: fixed;
		top: 0;
		
		width: 1px;
	}
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
	{
	opacity: 0;
}
</style>
