<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<li class="projects-tease">
		<img v-if="data.attributes.Thumbnail.data.attributes.ext.match((/\.(png|jpg|jpeg)$/))" :src="apiUrl + data.attributes.Thumbnail.data.attributes.url" alt="">
		<video ref="video" v-else :src="apiUrl + data.attributes.Thumbnail.data.attributes.url" crossorigin="anonymous" muted playsinline loop autoplay></video>
		<div class="projects-data" ref="textContent">
			<h2 class="projects-name project-title">{{ data.attributes.name }}</h2>
			<h3 class="projects-director project-director">{{ data.attributes.director.data.attributes.name }}</h3>
		</div>
		
	</li>
</template>
<script>
import store from '@/assets/js/store'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import E from '@/assets/js/utils/E'
gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'projectTease-comp',
  components: {},
  props: {
    data: Object
  },
  data () {
    return {
		apiUrl: process.env.VUE_APP_API,
	}
  },
  created () {
  },
  mounted () {
	this.$nextTick(() => {
		if(store.isLoaded) {
			store.Lenis.dimensions.resize()
			this.setScrollTrigger()
			gsap.set(this.$refs.textContent, {y: 100 + '%'})
			gsap.set(this.$refs.video, {opacity: 0})
			this.$refs.video.addEventListener('canplaythrough', () => {
				gsap.to(this.$refs.video, {opacity: 1, duration: 0.5})
				gsap.to(this.$refs.textContent, {y: 0 + '%', delay: 0.2, duration: 1, ease:'power2.out'})
			})
		}
		E.on('LoaderOut',() => {
			this.setScrollTrigger()
			gsap.from(this.$refs.video, {opacity: 0, duration: 0.5})
			gsap.from(this.$refs.textContent, {y: 100 + '%', delay: 0.2, duration: 1, ease:'power2.out'})
		})
	
	})
  },
  methods: {
	setScrollTrigger(){
		this.scrollText =  ScrollTrigger.create({
			trigger:this.$el,
			start: "center top",
			end: "bottom top",
			scrub: 1,
			onUpdate: (e) => {
				if(this.$refs.textContent){
					this.$refs.textContent.style.transform = `translate3D(0, ${this.easeInSine(e.progress) * 100}%,0)`
				}
			}
		})
		this.scrollVideo =  ScrollTrigger.create({
			trigger:this.$el,
			start: "top bottom",
			end: "top center",
			scrub: 1,
			onUpdate: (e) => {
				if(this.$refs.video){
					this.$refs.video.style.filter = `blur(${(1 - e.progress) * 50}px)`
				}
			}
		})
	},
	easeInSine(x) {
		return 1 - Math.cos((x * Math.PI) / 2);
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>