<template>
	<div class="video-track" @click="onInput" ref="cursorEl">
		<span class="progress" :style="`transform: scaleX(${percentage * 0.01})`" ></span>
		<!-- <input type="range" min="0" max="100" step="1" :value="percentage.toFixed(1)" @input="onInput" /> -->
	</div>
</template>

<script>
  export default {
	name: "VideoTrack",
	props: {
		percentage: { type: Number, required: true },
	},
	methods: {
		onInput(e) {
			this.$emit("seek", e.offsetX/this.$refs.cursorEl.getBoundingClientRect().width * 100);
		},
	},
  };
  </script>

<style lang="less">
input[type="range"] {
	position: relative;
	top: -1px;
	overflow: hidden;
	-webkit-appearance: none;
	background-color: rgba(255, 255, 255, 0.5);
	border-radius: 5px;
	left: 0;
}

.video-track{
	padding: 10px 0;
	position: relative;
	&::before{
		content: '';
		width: 100%;
		height: 1px;
		background-color: rgba(255, 255, 255, 0.5);
		left: 0;
		position: absolute
	}
}
.progress{
	background-color: white;
	position: absolute;
	width: 100%;
	height: 1px;
	transform-origin: 0;
	left: 0;
	transition: transform 0.2s;
}

input[type="range"]:focus {
	outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
	height: 8px;
	-webkit-appearance: none;
	color: #fff;
	margin-top: -1px;
}

input[type="range"]::-webkit-slider-thumb {
	width: 8px;
	-webkit-appearance: none;
	height: 8px;
	/* cursor: ew-resize; */
	background: #fff;
	box-shadow: -100% 0 0 245px #fff;
}
</style>