<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="directors-list" id="to-canvas">
		<ul>
			<li v-for='(item, key) in directors' :key="key" >
				<router-link :to="'talents/'+ item.attributes.slug" ref="director" @mousemove="mouseDirector" @mouseenter="(e) => { hoverDirector(e, key) }" @mouseleave="leaveDirector" v-html="item.attributes.name.replaceAll(/\\n/g, '&shy;')"></router-link>
				<video ref="video" :src="apiUrl + item.attributes.Thumbnail.data.attributes.url" crossorigin="anonymous" muted playsinline loop></video>
			</li>
		</ul>
	</div>
</template>
<script>
import { E } from '@/assets/js/utils'
import store from '@/assets/js/store'
import {Vector2} from 'three'
import axios from 'axios';
// import gsap from 'gsap'
export default {
  name: 'DirectorsList-comp',
  components: {},
  props: {
    data: Object
  },
  watch: {
	directors(){
		if(store.isLoaded){
			this.addNames()
		}
		this.$nextTick(() => {
			store.Lenis.dimensions.resize()
		})
	}
  },
  data () {
    return {
		directors: [],
		apiUrl: process.env.VUE_APP_API
	}
  },
  async created () {
	try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/directors?sort=rank:asc&populate=*`)
	//   c&fields[0]=name&fields[1]=Thumbnail
      this.directors = response.data.data
    } catch (error) {
      this.error = error;
    }
  },
  mounted () {
	if(!store.isLoaded){
		E.on('LoaderOut',() => {
			this.addNames()
			setTimeout(() => {
				E.emit('isPlainBackground', 0)
			}, 1)
		})
	} else {
		setTimeout(() => {
			E.emit('isPlainBackground', 0)
		}, 1)
	}
	
  },
  beforeUnmount(){
	this.$refs.director.forEach(el => {
		E.emit('domCanvasLeave', el.$el)
		E.emit('isPlainBackground', 1)
	})
  },
  methods: {
	addNames() {
		if(!store.isMobile){
			if(!store.isLoaded && !store.isMobile){
				E.on('PageLoaded',() => {
				setTimeout(() => {
					this.$refs.director.forEach((el, i) => {
						E.emit('domCanvasAdd', { el: el.$el, opacity: 0, y: 100, x: 0, id: i})
					})
				}, 10)
			})
			} else {
				this.$nextTick(() => {
					this.$refs.director.forEach((el, i) => {
						E.emit('domCanvasAdd', { el: el.$el, opacity: 0, y: 100, x: 0, id: i})
					})
				})
			}
		}
		
	},
	hoverDirector(e, key){
		if(this.hoverTimeout) clearTimeout(this.hoverTimeout);
		this.hoverTimeout = setTimeout(() => {
			E.emit('domCanvasOpacity', {el: e.target, value: 0})
			E.emit('setActiveVideo', this.$refs.video[key])
		}, 100)
	
	},
	leaveDirector(e){
		if(this.hoverTimeout) clearTimeout(this.hoverTimeout);
		E.emit('domCanvasOpacity', {el: e.target, value: 1})
	},
	mouseDirector(e){
		store.mousePos = new Vector2(e.clientX, e.clientY)
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>