<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<div class="home">
		<ProjectModal ref="modal" :open="projectOpen" :close="projectClose" :activeProject="activeProject"/>
		<ul class="projects" >
			<ProjectTease :data="project" @click="(e) => { selectItem(e, key) }" v-for="(project, key) in projects" :key="key"/>
		</ul>
	</div>
</template>
<script>
import axios from 'axios';
import {E} from '@/assets/js/utils'
import store from '@/assets/js/store'
import ProjectTease from '@/components/ProjectTease'
import ProjectModal from '@/components/ProjectModal'
import ScrollTrigger from 'gsap/ScrollTrigger'
import gsap from 'gsap'
gsap.registerPlugin(ScrollTrigger)
export default {
  name: 'Home-page',
  components: {
	ProjectTease,
	ProjectModal,
  },
  props: {
    data: Object,
	hideMenu: Function
  },
  data () {
    return {
		directors: [],
		projects: [],
		content: [],
		activeProject: {},
		projectOpen: false,
		count: 5,
		apiUrl: process.env.VUE_APP_API,
		videoTitle: 'tempVid'
	}
  },
  created () {
  },
  async mounted () {
	E.on('PageLoaded',() => {
		store.cursor.start()
	})
	setTimeout(() => {
		if(store.isLoaded) {
			store.cursor.start()
			store.Lenis.dimensions.resize()
		}
	}, 500)
	try {
      const response = await axios.get(`${process.env.VUE_APP_API}/api/projects?sort=rank:asc&pagination[limit]=${this.count}&populate=deep`)
      this.projects = response.data.data
    } catch (error) {
      this.error = error;
    }
  },
  methods: {
	selectItem(e, i){
		this.projectOpen = true
		this.activeProject = this.projects[i].attributes
		this.hideMenu(true)
	},
	projectClose(){
		this.projectOpen = false
		this.$refs.modal.showCredit = false
		// this.activeProject = {}
		this.hideMenu(false)
	}
  }
}
</script>
<style lang="less">
	@import '../../assets/less/_imports.less';
	@import "./style.less";
</style>